<template>
	<Dialog :dialog="customerDialog" :dialogWidth="dialogWidth">
		<template v-slot:title>
			<v-layout>
				<v-flex>Select Customer</v-flex>
				<v-flex class="text-right">
					<v-btn
						icon
						:disabled="searchLoader"
						class="mx-2 custom-grey-border custom-bold-button"
						v-on:click="$emit('close', true)"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template>
		<template v-slot:body>
			<v-container fluid>
				<template>
					<v-row>
						<v-col md="12">
							<v-text-field
								ref="search-customer"
								v-model.trim="searchCustomer"
								:loading="searchLoader"
								:background-color="!isFocused ? 'grey lighten-3' : undefined"
								:flat="!isFocused && !isSearching"
								:placeholder="placeholder"
								autocomplete="off"
								class="mx-2 mx-md-4 rounded-lg"
								dense
								hide-details
								solo
								@blur="isFocused = false"
								@focus="isFocused = true"
								@keydown.esc="onEsc"
								@keydown.enter="onEnter"
								@keydown.tab="onTab"
							>
								<template #prepend-inner>
									<v-icon :color="!isFocused ? 'grey' : undefined" class="ml-1 mr-2"> mdi-magnify </v-icon>
								</template>
							</v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<template>
							<v-col md="12">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll"
									style="max-height: 60vh; position: static"
								>
									<v-list class="mx-2 mx-md-4 border border-secondary" subheader three-line>
										<v-skeleton-loader
											v-if="searchLoader"
											class="custom-skeleton"
											type="list-item-avatar-two-line"
										>
										</v-skeleton-loader>
										<template v-else>
											<template v-if="customerList.length > 0">
												<v-list-item
													v-on:click="selectCustomer(data)"
													v-for="(data, index) in customerList"
													:key="index"
													link
												>
													<v-list-item-avatar>
														<ImageTemplate circle :src="data.image"></ImageTemplate>
													</v-list-item-avatar>
													<v-list-item-content>
														<v-list-item-title class="font-size-16">
															<Chip :text="data.barcode"></Chip>
														</v-list-item-title>
														<v-list-item-subtitle class="font-size-14">
															<v-tooltip top content-class="custom-top-tooltip">
																<template v-slot:activator="{ on, attrs }">
																	<span v-bind="attrs" v-on="on">
																		<b>{{ data.display_name }}</b>
																	</span>
																</template>
																<span> Display Name </span>
															</v-tooltip>
														</v-list-item-subtitle>
														<v-list-item-subtitle class="font-size-14">
															<v-tooltip top content-class="custom-top-tooltip">
																<template v-slot:activator="{ on, attrs }">
																	<span v-bind="attrs" v-on="on">
																		{{ data.full_name }}
																	</span>
																</template>
																<span> Full Name </span>
															</v-tooltip>
														</v-list-item-subtitle>
													</v-list-item-content>
													<v-list-item-action class="margin-auto">
														<v-list-item-action-text class="font-size-16"
															>{{ data.contact_person_count }} Contact Person(s)
														</v-list-item-action-text>
														<v-list-item-action-text class="font-size-16 mt-2"
															>{{ data.shipping_addresses_count }} Property(s)
														</v-list-item-action-text>
													</v-list-item-action>
												</v-list-item>
											</template>
											<template v-else>
												<v-list-item>
													<v-list-item-content>
														<v-list-item-title class="font-size-16">No Customer(s) Found </v-list-item-title>
													</v-list-item-content>
												</v-list-item>
											</template>
										</template>
									</v-list>
								</perfect-scrollbar>
							</v-col>
						</template>
					</v-row>
				</template>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn
				class="mx-2 custom-grey-border custom-bold-button"
				color="cyan white--text"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import { GetCustomerList } from "@/core/lib/contact.lib";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";

export default {
	data() {
		return {
			customerList: [],
			dialog: false,
			searchCustomer: null,
			formValid: true,
			formLoading: false,
			searchLoader: true,
			isFocused: false,
			timeoutLimit: 500,
			timeout: null,
		};
	},
	props: {
		customerDialog: {
			type: Boolean,
			default: false,
		},
		dialogWidth: {
			type: Number,
			default: 768,
		},
	},
	components: {
		Dialog,
		Chip,
		ImageTemplate,
	},
	watch: {
		customerDialog(param) {
			if (param) {
				this.getCustomers();
			}
		},
		searchCustomer(param) {
			if (!param) {
				const _this = this;
				clearTimeout(_this.timeout);
				_this.timeout = setTimeout(function () {
					_this.getCustomers();
				}, _this.timeoutLimit);
			}
		},
	},
	methods: {
		getCustomers() {
			const _this = this;
			GetCustomerList("customer", this.searchCustomer)
				.then((data) => {
					_this.customerList = data;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.searchLoader = false;
				});
		},
		selectCustomer(data) {
			this.$emit("selectCustomer", data.uuid);
		},
		onEsc() {
			this.resetSearch();
		},
		onEnter() {
			if (this.isSearching) {
				this.getCustomers();
			}
		},
		onTab() {
			if (this.isSearching) {
				this.getCustomers();
			}
		},
		resetSearch() {
			const _this = this;
			_this.$nextTick(() => {
				_this.searchCustomer = null;
				_this.isFocused = false;
			});
		},
	},
	computed: {
		isSearching() {
			return this.searchCustomer && this.searchCustomer.length > 0;
		},
		placeholder() {
			return this.isFocused ? "Start Typing then press enter" : "Search Customer...";
		},
	},
};
</script>
